import React from 'react'

import styled from 'styled-components'

const Container = styled.div`
    min-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    margin: auto;
    flex-direction: column;
`

const Title = styled.h1`
    margin: 0;
    padding: 1rem;
    text-align: center;
    line-height: 140%;
`

const Link = styled.a`
    display: block;
    color: ${props => props.theme.blue};
    letter-spacing: 1px;
    text-decoration: none;
`

export default ({ text, link }) => {
    return (
        <Container>
            <Title>{text}</Title>
            {link && <Link href={link.href}>{link.text}</Link>}
        </Container>
    )
}
