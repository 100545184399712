import React, { useEffect } from 'react'

import Layout from '../components/layout'

import SEO from '../components/seo'

import ComingSoon from '../components/coming-soon'

import Footer from '../components/footer'

import Header from '../components/header'

import { useLogout } from '../hooks'

export default () => {
    const logout = useLogout()
    useEffect(() => {
        logout()
        // eslint-disable-next-line
    }, [])
    return (
        <Layout>
            <Header mode="site-header" />
            <SEO title={'Logout'} />
            <ComingSoon text="Logging you out" />
            <Footer />
        </Layout>
    )
}
